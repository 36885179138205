.container-Header__CaseStudy {
  padding-top: 80px;
  background-color: var(--color-bg2);
}

.row-Header__CaseStudy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1300px;
  height: auto;
  margin: auto;
  padding: 30px;
}

.Image-Header__CaseStudy img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  margin: auto;
  border-radius: 15px;
}

.Image-Header__CaseStudy,
.heading-Header__CaseStudy,
.left-overview__CaseStudy,
.right-overview__CaseStudy {
  flex: 1;
}

.title-Header__CaseStudy {
  font-size: var(--fs-5);
  font-weight: 600;
}

.Overview__CaseStudy {
}

.row-Overview__CaseStudy {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  height: 80vh;
  width: 1300px;
  margin: auto;
}

.left-overview__CaseStudy {
  padding: 0 50px;
}

.right-overview__CaseStudy {
}

.numbers-Result__CaseStudy {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

.numbers-Result__CaseStudy span {
  display: block;
}

.digits-Result__CaseStudy {
  font-size: var(--fs-3);
  font-weight: 600;
}

.lead-overview__CaseStudy {
  font-size: var(--fs-1);
  font-weight: 500;
}

.container-gallery__CaseStudy {
  background-color: var(--color-bg2);
}
.row-gallery__CaseStudy {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  height: auto;
  padding: 50px;
}

.Image-gallery__CaseStudy {
  flex: 1;
  width: 40%;
  height: auto;
  border-radius: 15px;
}

.row__Testimonials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1300px;
  height: 60vh;
  margin: auto;
  padding: 40px 15px;
}

.opHeading-overview__CaseStudy,
.description-testimonials {
  margin: auto;
}

.description-testimonials {
  font-size: var(--fs-3);
  font-weight: 500;
}

.container__MoreCaseStudies {
  padding: 30px;
}

.row__MoreCaseStudies {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 1300px;
  margin: auto;
}

.Heading__MoreCaseStudies {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cards__MoreCaseStudies {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  gap: 30px;
}

.card-More {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 400px;
  height: auto;
  background-color: var(--color-bg2);
  border-radius: 15px;
  padding: 15px;
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Case Study / Projects CSS  */
@media screen and (max-width:1024px) {
   .container-Header__CaseStudy {
    padding-top: 5rem;
  }

  .row-Header__CaseStudy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    padding: 1.25rem;
  }

  .row-Overview__CaseStudy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .left-overview__CaseStudy {
    padding: 10px 5px;
  }

  .numbers-Result__CaseStudy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    width: 70%;
    margin: auto;
    padding-top: 50px;
    text-align: center;
  }

  .row-gallery__CaseStudy {
    gap: 30px;
    padding: 20px;
  }

  .Image-gallery__CaseStudy {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .row__Testimonials {
    width: 100%;
    height: auto;
  }

  .topHeading-testimonials__CaseStudy {
    padding: 30px 0;
  }

  .description-testimonials {
    font-size: var(--fs-1);
    font-weight: 500;
    font-style: italic;
  }

  .container__MoreCaseStudies {
    padding: 5px;
  }

  .row__MoreCaseStudies {
    width: 100%;
    margin: auto;
  }

  .cards__MoreCaseStudies{
    padding: 10px;
  }

  .card-More {
    width: 100%;
    height: auto;
    margin: auto;
  }
}
@media screen and (max-width: 768px) {
    .container-Header__CaseStudy {
    padding-top: 5rem;
  }

  .row-Header__CaseStudy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    padding: 1.25rem;
  }

  .row-Overview__CaseStudy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .left-overview__CaseStudy {
    padding: 10px 5px;
  }

  .numbers-Result__CaseStudy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    width: 70%;
    margin: auto;
    padding-top: 50px;
    text-align: center;
  }

  .row-gallery__CaseStudy {
    gap: 30px;
    padding: 20px;
  }

  .Image-gallery__CaseStudy {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .row__Testimonials {
    width: 100%;
    height: auto;
  }

  .topHeading-testimonials__CaseStudy {
    padding: 30px 0;
  }

  .description-testimonials {
    font-size: var(--fs-1);
    font-weight: 500;
    font-style: italic;
  }

  .container__MoreCaseStudies {
    padding: 5px;
  }

  .row__MoreCaseStudies {
    width: 100%;
    margin: auto;
  }

  .cards__MoreCaseStudies{
    padding: 10px;
  }

  .card-More {
    width: 100%;
    height: auto;
    margin: auto;
  }
}

@media screen and (max-width: 540px) {
    .container-Header__CaseStudy {
    padding-top: 5rem;
  }

  .row-Header__CaseStudy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    padding: 1.25rem;
  }

  .row-Overview__CaseStudy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .left-overview__CaseStudy {
    padding: 10px 5px;
  }

  .numbers-Result__CaseStudy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    width: 70%;
    margin: auto;
    padding-top: 50px;
    text-align: center;
  }

  .row-gallery__CaseStudy {
    gap: 30px;
    padding: 20px;
  }

  .Image-gallery__CaseStudy {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .row__Testimonials {
    width: 100%;
    height: auto;
  }

  .topHeading-testimonials__CaseStudy {
    padding: 30px 0;
  }

  .description-testimonials {
    font-size: var(--fs-1);
    font-weight: 500;
    font-style: italic;
  }

  .container__MoreCaseStudies {
    padding: 5px;
  }

  .row__MoreCaseStudies {
    width: 100%;
    margin: auto;
  }

  .cards__MoreCaseStudies{
    padding: 10px;
  }

  .card-More {
    width: 100%;
    height: auto;
    margin: auto;
  }
}

@media screen and (max-width: 450px) {
    .container-Header__CaseStudy {
    padding-top: 5rem;
  }

  .row-Header__CaseStudy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    padding: 1.25rem;
  }

  .row-Overview__CaseStudy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .left-overview__CaseStudy {
    padding: 10px 5px;
  }

  .numbers-Result__CaseStudy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    width: 70%;
    margin: auto;
    padding-top: 50px;
    text-align: center;
  }

  .row-gallery__CaseStudy {
    gap: 30px;
    padding: 20px;
  }

  .Image-gallery__CaseStudy {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .row__Testimonials {
    width: 100%;
    height: auto;
  }

  .topHeading-testimonials__CaseStudy {
    padding: 30px 0;
  }

  .description-testimonials {
    font-size: var(--fs-1);
    font-weight: 500;
    font-style: italic;
  }

  .container__MoreCaseStudies {
    padding: 5px;
  }

  .row__MoreCaseStudies {
    width: 100%;
    margin: auto;
  }

  .cards__MoreCaseStudies{
    padding: 10px;
  }

  .card-More {
    width: 100%;
    height: auto;
    margin: auto;
  }
}

@media screen and (max-width: 376px) {
   .container-Header__CaseStudy {
    padding-top: 5rem;
  }

  .row-Header__CaseStudy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    padding: 1.25rem;
  }

  .row-Overview__CaseStudy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .left-overview__CaseStudy {
    padding: 10px 5px;
  }

  .numbers-Result__CaseStudy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    width: 70%;
    margin: auto;
    padding-top: 50px;
    text-align: center;
  }

  .row-gallery__CaseStudy {
    gap: 30px;
    padding: 20px;
  }

  .Image-gallery__CaseStudy {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .row__Testimonials {
    width: 100%;
    height: auto;
  }

  .topHeading-testimonials__CaseStudy {
    padding: 30px 0;
  }

  .description-testimonials {
    font-size: var(--fs-1);
    font-weight: 500;
    font-style: italic;
  }

  .container__MoreCaseStudies {
    padding: 5px;
  }

  .row__MoreCaseStudies {
    width: 100%;
    margin: auto;
  }

  .cards__MoreCaseStudies{
    padding: 10px;
  }

  .card-More {
    width: 100%;
    height: auto;
    margin: auto;
  }
}

@media screen and (max-width: 360px) {
  .container-Header__CaseStudy {
    padding-top: 5rem;
  }

  .row-Header__CaseStudy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    padding: 1.25rem;
  }

  .row-Overview__CaseStudy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .left-overview__CaseStudy {
    padding: 10px 5px;
  }

  .numbers-Result__CaseStudy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    width: 70%;
    margin: auto;
    padding-top: 50px;
    text-align: center;
  }

  .row-gallery__CaseStudy {
    gap: 30px;
    padding: 20px;
  }

  .Image-gallery__CaseStudy {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .row__Testimonials {
    width: 100%;
    height: auto;
  }

  .topHeading-testimonials__CaseStudy {
    padding: 30px 0;
  }

  .description-testimonials {
    font-size: var(--fs-1);
    font-weight: 500;
    font-style: italic;
  }

  .container__MoreCaseStudies {
    padding: 5px;
  }

  .row__MoreCaseStudies {
    width: 100%;
    margin: auto;
  }

  .cards__MoreCaseStudies{
    padding: 10px;
  }

  .card-More {
    width: 100%;
    height: auto;
    margin: auto;
  }
}

/* Ends - Media Queries - Footer Component CSS  */
