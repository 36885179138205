.container__IntroEdu {
  position: relative;
  height: 100vh;
  z-index: 1;
  background: #bdc3c7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f4bd98,
    #bdc3c7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f4bd98,
    #bdc3c7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.row__IntroEdu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3.125rem;
  width: 1300px;
  height: 100%;
  margin: auto;
}

.left__IntroEdu {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 3;
}

.text__IntroEdu {
  padding-bottom: 20px;
}

.right__IntroEdu {
  flex: 1;
  background-color: rgba(14, 83, 127, 0.5);
  backdrop-filter: blur(2px);
  border-radius: 20px;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.object__IntroEdu {
  width: 90%;
  height: auto;
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Footer Component CSS  */
@media screen and (max-width: 1024px) {
      .container__IntroEdu {
    height: auto;
    padding: 20px;
  }

  .row__IntroEdu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .button__IntroEdu {
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
      .container__IntroEdu {
    height: auto;
    padding: 20px;
  }

  .row__IntroEdu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .button__IntroEdu {
    margin: auto;
  }
}

@media screen and (max-width: 540px) {
    .container__IntroEdu {
    height: auto;
    padding: 20px;
  }

  .row__IntroEdu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .button__IntroEdu {
    margin: auto;
  }
}

@media screen and (max-width: 450px) {
    .container__IntroEdu {
    height: auto;
    padding: 20px;
  }

  .row__IntroEdu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .button__IntroEdu {
    margin: auto;
  }
}

@media screen and (max-width: 376px) {
    .container__IntroEdu {
    height: auto;
    padding: 20px;
  }

  .row__IntroEdu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .button__IntroEdu {
    margin: auto;
  }
}

@media screen and (max-width: 360px) {
  .container__IntroEdu {
    height: auto;
    padding: 20px;
  }

  .row__IntroEdu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .button__IntroEdu {
    margin: auto;
  }
}
/* Ends - Media Queries - Footer Component CSS  */
