.container__Pillars {
  height: auto;
}

.row__Pillars {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  width: 1300px;
  padding: 30px;
  margin: auto;
}

.heading__Pillars,
.awareness__Pillars,
.advocacy__Pillars,
.Image__Pillars {
  flex: 500px;
  padding: 30px 20px;
  border-radius: 15px;
}

.heading__Pillars {
background-color: var(--color-text);
  color: var(--color-bg);
}


.Image__Pillars {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.Image__Pillars img{
 border-radius: 15px;
}

.link__Pillars{
    color: var(--color-light);
}

.link__Pillars:hover{
    font-weight: 500;
    text-decoration: underline;
}



/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Pillars Component CSS  */
@media screen and (max-width:1024px) {
    .container__Pillars{
    height: auto;
  }

  .row__Pillars{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 60%;
    padding: 0 20px;
  }

  .heading__Pillars,
.awareness__Pillars,
.advocacy__Pillars,
.Image__Pillars {
  flex: 100%;
  padding: 30px 10px;
  border-radius: 15px;
}
}

@media screen and (max-width:768px) {
    .container__Pillars{
    height: auto;
  }

  .row__Pillars{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 70%;
    padding: 0 20px;
  }

  .heading__Pillars,
.awareness__Pillars,
.advocacy__Pillars,
.Image__Pillars {
  flex: 100%;
  padding: 30px 10px;
  border-radius: 15px;
}
}

@media screen and (max-width:540px) {
    .container__Pillars{
    height: auto;
  }

  .row__Pillars{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 90%;
    padding: 0 20px;
  }

  .heading__Pillars,
.awareness__Pillars,
.advocacy__Pillars,
.Image__Pillars {
  flex: 100%;
  padding: 30px 10px;
  border-radius: 15px;
}
}

@media screen and (max-width:450px) {
    .container__Pillars{
    height: auto;
  }

  .row__Pillars{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 0 20px;
  }

  .heading__Pillars,
.awareness__Pillars,
.advocacy__Pillars,
.Image__Pillars {
  flex: 100%;
  padding: 30px 10px;
  border-radius: 15px;
}
}

@media screen and (max-width:376px) {
    .container__Pillars{
    height: auto;
  }

  .row__Pillars{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 0 20px;
  }

  .heading__Pillars,
.awareness__Pillars,
.advocacy__Pillars,
.Image__Pillars {
  flex: 100%;
  padding: 30px 10px;
  border-radius: 15px;
}
}

@media screen and (max-width:360px) {
  .container__Pillars{
    height: auto;
  }

  .row__Pillars{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 0 20px;
  }

  .heading__Pillars,
.awareness__Pillars,
.advocacy__Pillars,
.Image__Pillars {
  flex: 100%;
  padding: 30px 10px;
  border-radius: 15px;
}
}

/* Ends - Media Queries - Pillars Component CSS  */