.container__Initiatives {
  position: relative;
  z-index: 1;
  /* background-color: var(--color-bg2); */
  background-image: linear-gradient(
    360deg,
    rgba(53, 50, 51) 12.1%,
    /* rgba(151, 150, 240, 1) 12.1%, */ rgba(189, 189, 189) 63.2%
  );
  padding: 20px;
}

.container__Initiatives,
.BgImage__Initiatives {
  height: auto;
}

.title__Initiatives {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 60%;
  margin: auto;
  padding: 30px 10px;
}

.topHeading__Initiatives,
.heading__Initiatives {
  display: block;
  margin-bottom: 20px;
}

.cards__Initiatives {
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.card {
  background-color: var(--color-bg);
  border-radius: 10px;
  width: 580px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.card_left {
  flex: 1;
   margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.card_right {
  flex: 2;
  font-size: var(--fs--1);
  padding-left: 25px;
  padding-right: 25px;
}

.card_left img {
  width: 115px;
  height: auto;
  padding-bottom: 20px;
}

.smallAction {
  display: block;
  height: 37px;
  width: 130px;
  background-color: var(--color-light);
  color: var(--color-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.smallAction:hover {
  background-color: var(--color-main);
  color: var(--color-bg);
  border: transparent;
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Initiatives Component CSS  */
@media screen and (max-width:1024px) {
  .title__Initiatives{
width: 100%;
  margin: auto;
  padding: 30px 10px;
}

.card_left{
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

  .card_right{
    display: none;
  }
}

@media screen and (max-width:768px) {
  .title__Initiatives{
width: 100%;
  margin: auto;
  padding: 30px 10px;
}

.card_left{
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

  .card_right{
    display: none;
  }
}

@media screen and (max-width:540px) {
  .title__Initiatives{
width: 100%;
  margin: auto;
  padding: 30px 10px;
}

.card_left{
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

  .card_right{
    display: none;
  }
}

@media screen and (max-width:450px) {
  .title__Initiatives{
width: 100%;
  margin: auto;
  padding: 30px 10px;
}

.card_left{
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

  .card_right{
    display: none;
  }
}

@media screen and (max-width:376px) {
  .title__Initiatives{
width: 100%;
  margin: auto;
  padding: 30px 10px;
}

.card_left{
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

  .card_right{
    display: none;
  }
}

@media screen and (max-width:360px) {
.title__Initiatives{
width: 100%;
  margin: auto;
  padding: 30px 10px;
}

.card_left{
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

  .card_right{
    display: none;
  }
}

/* Ends - Media Queries - Initiatives Component CSS  */
