.container__Teams {
  background-color: var(--color-bg2);
  height: auto;
  padding-top: 80px;
  padding-bottom: 50px;
}

.row__Teams {
  width: 1300px;
  margin: auto;
}

.top__Teams {
  width: 70ch;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

.bottom__Teams {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.Image--profile__Teams {
  width: 100%;
  height: auto;
  border-top-left-radius: 0.9375rem;
  border-top-right-radius: 15px;
}

.profile__Teams {
  background-color: var(--color-bg);
  width: 250px;
  border-radius: 15px;
}
.text--profile__Teams {
  background-color: var(--color-bg);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 10px;
}

.text--profile__Teams p {
  font-size: var(--fs--1);
}

.divider__Teams {
  width: 50%;
  margin-top: 80px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

.heading-advisors {
  text-align: center;
  margin-bottom: 20px;
}

.end__Teams {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  width: 1000px;
  margin: auto;
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Teams Component CSS  */
@media screen and (max-width: 1024px) {
    .container__Teams {
    height: auto;
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .row__Teams {
    width: 100%;
  }

  .top__Teams {
    width: 100%;
    padding: 50px 20px;
  }

  .profile__Teams {
    width: 200px;
    height: auto;
  }

  .end__Teams{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.875rem;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .container__Teams {
    height: auto;
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .row__Teams {
    width: 70%;
  }

  .top__Teams {
    width: 100%;
    padding: 50px 20px;
  }

  .profile__Teams {
    width: 200px;
    height: auto;
  }

    .end__Teams{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.875rem;
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .container__Teams {
    height: auto;
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .row__Teams {
    width: 100%;
  }

  .top__Teams {
    width: 100%;
    padding: 50px 20px;
  }

  .profile__Teams {
    width: 200px;
    height: auto;
  }

    .end__Teams{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.875rem;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .container__Teams {
    height: auto;
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .row__Teams {
    width: 100%;
  }

  .top__Teams {
    width: 100%;
    padding: 50px 20px;
  }

  .profile__Teams {
    width: 300px;
  }

    .end__Teams{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.875rem;
    width: 100%;
  }
}

@media screen and (max-width: 376px) {
  .container__Teams {
    height: auto;
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .row__Teams {
    width: 100%;
  }

  .top__Teams {
    width: 100%;
    padding: 50px 20px;
  }

  .profile__Teams {
    width: 300px;
  }

    .end__Teams{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.875rem;
    width: 100%;
  }
}

@media screen and (max-width: 360px) {
  .container__Teams {
    height: auto;
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .row__Teams {
    width: 100%;
  }

  .top__Teams {
    width: 100%;
    padding: 50px 20px;
  }

  .profile__Teams {
    width: 300px;
  }

    .end__Teams{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.875rem;
    width: 100%;
  }
}

/* Ends - Media Queries - Teams Component CSS  */
