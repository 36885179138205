.container__Membership {
  position: relative;
  z-index: 1;
}

.BgImage__Membership {
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 2;
}

.container__Membership,.BgImage__Membership{
  height: 100vh;
}

.topHeading__Membership,
.heading__Membership,
.description,
.heading_right {
  display: block;
}

.row__Membership {
  position: absolute;
  top: 16%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding-left: 70px;
  padding-right: 70px;
}

.left__Membership,
.right__Membership {
  margin: 10px;
}

.left__Membership {
  flex: 50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.right__Membership {
  background-color: rgba(53, 50, 51, 0.5);
  backdrop-filter: blur(2px);
  flex: 50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
padding: 90px 20px;
}

.right__Membership ul {
  line-height: 2;
  font-size: var(--fs-0);
  font-weight: 400;
  width: 85%;
}

.heading_right {
  font-size: var(--fs-2);
  font-weight: 600;
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Footer Component CSS  */
@media screen and (max-width: 1366px){
  .BgImage__Membership,
  .container__Membership {
    height: ;
  }
}

@media screen and (max-width: 1024px) {
    .BgImage__Membership,
  .container__Membership {
    height: auto;
  }
  .row__Membership {
    position: absolute;
    top: 10%;
    width: 100%;
  }
  .left__Membership{
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .BgImage__Membership,
  .container__Membership {
    height: 800px;
  }
  .row__Membership {
    position: absolute;
    top: 7%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  .left__Membership {
    flex: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
  }
  .right__Membership {
    flex: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .right__Membership ul {
    line-height: 2.2;
    font-size: var(--fs-0);
    font-weight: 400;
    width: 100%;
  }

  .heading_right {
    font-size: var(--fs-2);
    font-weight: 600;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 540px) {
  .BgImage__Membership,
  .container__Membership {
    height: 900px;
  }
  .row__Membership {
    position: absolute;
    top: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  .left__Membership {
    flex: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
  }
  .right__Membership {
    flex: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .right__Membership ul {
    line-height: 2.2;
    font-size: var(--fs-0);
    font-weight: 400;
    width: 100%;
  }

  .heading_right {
    font-size: var(--fs-2);
    font-weight: 600;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 450px) {
  .BgImage__Membership,
  .container__Membership {
    height: 1100px;
  }
  .row__Membership {
    position: absolute;
    top: 8%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  .left__Membership {
    flex: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
  }
  .right__Membership {
    flex: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .right__Membership ul {
    line-height: 2.2;
    font-size: var(--fs-0);
    font-weight: 400;
    width: 100%;
  }

  .heading_right {
    font-size: var(--fs-2);
    font-weight: 600;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 376px) {
  .BgImage__Membership,
  .container__Membership {
    height: 1100px;
  }
  .row__Membership {
    position: absolute;
    top: 7%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  .left__Membership {
    flex: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
  }
  .right__Membership {
    flex: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .right__Membership ul {
    line-height: 2.2;
    font-size: var(--fs-0);
    font-weight: 400;
    width: 100%;
  }

  .heading_right {
    font-size: var(--fs-2);
    font-weight: 600;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 360px) {
  .BgImage__Membership,
  .container__Membership {
    height: 1100px;
  }
  .row__Membership {
    position: absolute;
    top: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  .left__Membership {
    flex: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
  }
  .right__Membership {
    flex: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .right__Membership ul {
    line-height: 2.2;
    font-size: var(--fs-0);
    font-weight: 400;
    width: 100%;
  }

  .heading_right {
    font-size: var(--fs-2);
    font-weight: 600;
    margin-bottom: 20px;
  }
}

/* Ends - Media Queries - Footer Component CSS  */
