.container__header {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.bgImage__header {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  z-index: 2;
}

.heading__header {
  position: absolute;
  top: 22%;
  width: 60%;
  left: 18%;
  text-align: center;
}

.heading__header h1 {
  padding-bottom: 30px;
}

.description__header {
  padding-bottom: 10px;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.bottom__header {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 30px;
}

.button {
  display: block;
  height: 50px;
  width: 200px;
  background-color: var(--color-light);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.button:hover {
  background-color: var(--color-main);
  color: var(--color-bg);
  border: transparent;
}

.ActionOne {
  color: var(--color-bg);
  background-color: var(--color-light);
}

.ActionTwo {
  color: var(--color-text);
  background-color: transparent;
  border: solid 2px var(--color-light);
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Footer Component CSS  */
@media screen and (max-width: 1920px) {
}
@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1024px) {
  .container__header,
  .bgImage__header {
    height: 800px;
  }
  .heading__header {
    position: absolute;
    top: 30%;
    width: 80%;
    left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .heading__header h1 {
    font-size: var(--fs-6);
    line-height: 1.15;
    padding-bottom: 30px;
  }

  .heading__header p {
    font-size: var(--fs-1);
    padding-bottom: 20px;
    width: 70%;
  }

  .bottom__header {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .container__header,
  .bgImage__header {
    height: 780px;
  }
  .heading__header {
    position: absolute;
    top: 25%;
    width: 80%;
    left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .heading__header h1 {
    font-size: var(--fs-5);
    line-height: 1.15;
    padding-bottom: 30px;
  }

  .heading__header p {
    font-size: var(--fs-1);
    padding-bottom: 20px;
    width: 80%;
  }

  .bottom__header {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 540px) {
  .container__header,
  .bgImage__header {
    height: 680px;
  }
  .heading__header {
    position: absolute;
    top: 25%;
    width: 80%;
    left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.3125rem;
  }

  .heading__header h1 {
    font-size: var(--fs-5);
    line-height: 1.15;
    padding-bottom: 0.9375rem;
  }

  .heading__header p {
    font-size: var(--fs-0);
    padding-bottom: 5px;
    width: 95%;
  }

  .bottom__header {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 450px) {
  .container__header,
  .bgImage__header {
    height: 100vh;
  }

  .heading__header {
    position: absolute;
    top: 25%;
    width: 80%;
    left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .heading__header h1 {
    font-size: var(--fs-4);
    line-height: 1.15;
    padding-bottom: 15px;
  }

  .heading__header p {
    font-size: var(--fs-0);
    padding-bottom: 5px;
    width: 95%;
  }

  .bottom__header {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 375px) {
  .container__header,
  .bgImage__header {
    height: 100vh;
  }
  .container__header {
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 1;
  }

  .bgImage__header {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;
    z-index: 2;
  }

  .heading__header {
    position: absolute;
    top: 27%;
    width: 80%;
    left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .heading__header h1 {
    font-size: var(--fs-4);
    line-height: 1.15;
    padding-bottom: 15px;
  }

  .heading__header p {
    font-size: var(--fs-0);
    padding-bottom: 5px;
    width: 95%;
  }

  .bottom__header {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 360px) {
  .container__header,
  .bgImage__header {
    height: 100vh;
  }
  .container__header {
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 1;
  }

  .bgImage__header {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;
    z-index: 2;
  }

  .heading__header {
    position: absolute;
    top: 25%;
    width: 80%;
    left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .heading__header h1 {
    font-size: var(--fs-4);
    line-height: 1.5;
    padding-bottom: 5px;
  }

  .heading__header p {
    font-size: var(--fs-0);
    font-weight: 300;
    padding-bottom: 5px;
    width: 95%;
  }

  .bottom__header {
    display: flex;
    flex-direction: column;
  }
}

/* Ends - Media Queries - Footer Component CSS  */
