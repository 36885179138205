.container__Partners {
  position: relative;
  height: auto;
  z-index: 1;
  background-color: var(--color-bg2);
  padding: 80px 20px;
}

.title__Partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-align: center;
  width: 60%;
  margin: auto;
  padding: 20px 10px;
}

.logos__Partners{
  padding: 30px 20px;
}

.heading-cards__Partners{
  padding: 40px 10px;
}

.card__Partners {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.row-card__Partners{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

.icon__partner {
  width: 280px;
  height: auto;
  background-color: var(--color-bg);
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/


@media screen and (max-width:1024px) {}

@media screen and (max-width:768px) {}

@media screen and (max-width:540px) {
    .title__Partners{
    width: 100%;
  }
  .heading-cards__Partners{
    text-align: center;
  padding: 40px 10px;
}
}

@media screen and (max-width:450px) {
    .title__Partners{
    width: 100%;
  }
  .heading-cards__Partners{
    text-align: center;
  padding: 40px 10px;
}
}

@media screen and (max-width:376px) {
    .title__Partners{
    width: 100%;
  }
  .heading-cards__Partners{
    text-align: center;
  padding: 40px 10px;
}
}

@media screen and (max-width:360px) {
  .title__Partners{
    width: 100%;
  }
  .heading-cards__Partners{
    text-align: center;
  padding: 40px 10px;
}
}