.container-header__Article {
  background-color: var(--color-bg2);
  height: 40vh;
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.omb-header{
  background-image: url('../../../public/images/bgs/omb.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 70vh;
}

.ttt-header{
 background-image: url('../../../public/images/projects/ttt.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 70vh;
}

.techcon-header{
 background-image: url('../../../public/images/bgs/techcon.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 70vh;
}

.main__Article {
  padding: 30px 0;
}

.wrapper__Article {
  width: 60ch;
  margin: auto;
}

.row-case-card__Article {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 10px 0;
}

.heading__case-card {
  margin: 5px 0;
}

.topHeading__case-card {
  font-size: var(--fs--1);
}

.case-card__Article {
  flex-basis: 300px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  background-color: var(--color-bg2);
  width: 300px;
  height: auto;
  border-radius: 15px;
  padding: 15px;
}

.Image__case-card {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  margin-bottom: 10px;
}

.case-card__Article p {
  font-size: var(--fs--1);
  /* margin-bottom: 15px; */
}

.cta__case-card {
  margin: 10px 0px;
  padding: 15px 15px;
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Footer Component CSS  */
@media screen and (max-width: 1024px) {
      .container-header__Article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main__Article {
    padding: 30px 10px;
  }

  .wrapper__Article {
    width: 100%;
    margin: auto;
    padding: 20px;
  }

  .row-case-card__Article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    padding: 10px 0;
  }
}

@media screen and (max-width: 768px) {
    .container-header__Article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main__Article {
    padding: 30px 10px;
  }

  .wrapper__Article {
    width: 100%;
    margin: auto;
    padding: 20px;
  }

  .row-case-card__Article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    padding: 10px 0;
  }
}

@media screen and (max-width: 540px) {
    .container-header__Article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main__Article {
    padding: 30px 10px;
  }

  .wrapper__Article {
    width: 100%;
    margin: auto;
    padding: 20px;
  }

  .row-case-card__Article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 10px 0;
  }
}

@media screen and (max-width: 450px) {
    .container-header__Article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main__Article {
    padding: 30px 10px;
  }

  .wrapper__Article {
    width: 100%;
    margin: auto;
    padding: 20px;
  }

  .row-case-card__Article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 10px 0;
  }
}

@media screen and (max-width: 376px) {
    .container-header__Article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main__Article {
    padding: 30px 10px;
  }

  .wrapper__Article {
    width: 100%;
    margin: auto;
    padding: 20px;
  }

  .row-case-card__Article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 10px 0;
  }
}

@media screen and (max-width: 360px) {
  .container-header__Article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main__Article {
    padding: 30px 10px;
  }

  .wrapper__Article {
    width: 100%;
    margin: auto;
    padding: 20px;
  }

  .row-case-card__Article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 10px 0;
  }
}

/* Ends - Media Queries - Footer Component CSS  */
