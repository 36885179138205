.container__Contact {
  position: relative;
  z-index: 1;
  background-color: var(--color-light);
}

.BgImage__Contact {
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 2;
}

.container__Contact,
.BgImage__Contact {
  height: 800px;
}

.container__Contact span {
  display: block;
}

.row__container {
  position: absolute;
  top: 0.2%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 60px;
}

.left__Contact,
.right__Contact {
  flex: 1;
}

.left__Contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 40px;
  text-align: center;
}

.description__Contact{

  padding: 0 30px ;
}

.right__Contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: rgba(244, 189, 152, 0.5);
  backdrop-filter: blur(0.125rem);
  border-radius: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.right__Contact input {
  display: block;
  border-radius: 5px;
  border: none;
  width: 80%;
  height: 45px;
}

.right__Contact label {
  align-self: flex-start;
  padding-left: 60px;
  font-weight: 600;
  font-size: var(--fs--1);
}

.fields,.right__Contact textarea{
  font-size: var(--fs--1);
  font-weight: 300;
  padding-left: 20px;
}

.right__Contact textarea {
  display: block;
  border-radius: 5px;
  border: none;
  width: 80%;
  padding: 20px;
}

.buttons__Contact{
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 10px;
  width: 80%;
}



/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Contact Form Component CSS  */
@media screen and (max-width: 1366px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
     .container__Contact,
  .BgImage__Contact {
    height: 1100px;
  }
  .row__container {
    position: absolute;
    top: 3%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 2px;
  }

  .left__Contact {
    width: 75%;
    gap: 25px;
    padding: 20px;
    text-align: center;
  }

  .right__Contact {
    gap: 10px;
    width: 70%;
    padding: 35px 10px;
  }

  .heading__Contact {
    font-size: var(--fs-4);
  }

  .description__Contact {
    font-size: var(--fs-0);
  }

  .right__Contact input, .right__Contact textarea {
    width: 95%;
  }

.right__Contact label {
  padding-left: 17px;
}
}

@media screen and (max-width: 540px) {
     .container__Contact,
  .BgImage__Contact {
    height: 1100px;
  }
  .row__container {
    position: absolute;
    top: 3%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 2px;
  }

  .left__Contact {
    gap: 25px;
    padding: 20px;
    text-align: center;
  }

  .right__Contact {
    gap: 10px;
    width: 90%;
    padding: 35px 10px;
  }

  .heading__Contact {
    font-size: var(--fs-4);
  }

  .description__Contact {
    font-size: var(--fs-0);
  }

  .right__Contact input, .right__Contact textarea {
    width: 95%;
  }

.right__Contact label {
  padding-left: 17px;
}
}

@media screen and (max-width: 450px) {
   .container__Contact,
  .BgImage__Contact {
    height: 1300px;
  }
  .row__container {
    position: absolute;
    top: 3%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 2px;
  }

  .left__Contact {
    gap: 25px;
    padding: 20px;
    text-align: center;
  }

  .right__Contact {
    gap: 10px;
    width: 90%;
    padding: 35px 10px;
  }

  .heading__Contact {
    font-size: var(--fs-4);
  }

  .description__Contact {
    font-size: var(--fs-0);
  }

  .right__Contact input, .right__Contact textarea {
    width: 95%;
  }

.right__Contact label {
  padding-left: 17px;
}
}

@media screen and (max-width: 376px) {
    .container__Contact,
  .BgImage__Contact {
    height: 1300px;
  }
  .row__container {
    position: absolute;
    top: 3%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 2px;
  }

  .left__Contact {
    gap: 25px;
    padding: 20px;
    text-align: center;
  }

  .right__Contact {
    gap: 10px;
    width: 90%;
    padding: 35px 10px;
  }

  .heading__Contact {
    font-size: var(--fs-4);
  }

  .description__Contact {
    font-size: var(--fs-0);
  }

  .right__Contact input, .right__Contact textarea {
    width: 95%;
  }

.right__Contact label {
  padding-left: 17px;
}
}

@media screen and (max-width: 360px) {
  .container__Contact,
  .BgImage__Contact {
    height: 1300px;
  }
  .row__container {
    position: absolute;
    top: 5%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 2px;
  }

  .left__Contact {
    gap: 25px;
    padding: 5px;
    text-align: center;
  }


  .right__Contact {
    gap: 10px;
    width: 90%;
    padding: 35px 10px;
  }

  .heading__Contact {
    font-size: var(--fs-4);
  }

  .description__Contact {
    font-size: var(--fs-0);
  }

  .right__Contact input, .right__Contact textarea {
    width: 95%;
  }

.right__Contact label {
  padding-left: 17px;
}
}

/* Ends - Media Queries - Contact Form Component CSS  */
