.container__headerAbout {
  background-image: url("../../../public/images/bgs/resources.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  padding-top: 90px;
}

.row__headerAbout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin: auto;
  padding-top: 8%;
}

.description__headerAbout {
  font-weight: 600;
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Footer Component CSS  */
@media screen and (max-width: 3840px) {
}
@media screen and (max-width: 1920px) {
}
@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1536px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 1024px) {
     .container__headerAbout {
    height: auto;
  }

  .row__headerAbout {
    width: 100%;
    margin: auto;
    padding: 8% 5%;
  }

  .heading__headerAbout {
    font-size: var(--fs-6);
    line-height: 3rem;
  }

  .description__headerAbout {
    font-weight: 500;
  }
}

@media screen and (max-width: 768px) {
     .container__headerAbout {
    height: auto;
  }

  .row__headerAbout {
    width: 100%;
    margin: auto;
    padding: 8% 5%;
  }

  .heading__headerAbout {
    font-size: var(--fs-6);
    line-height: 3rem;
  }

  .description__headerAbout {
    font-weight: 500;
  }
}

@media screen and (max-width: 540px) {
     .container__headerAbout {
    height: auto;
  }

  .row__headerAbout {
    width: 100%;
    margin: auto;
    padding: 8% 5%;
  }

  .heading__headerAbout {
    font-size: var(--fs-5);
    line-height: 3rem;
  }

  .description__headerAbout {
    font-weight: 500;
  }
}

@media screen and (max-width: 450px) {
  .container__headerAbout {
    height: auto;
  }

  .row__headerAbout {
    width: 100%;
    margin: auto;
    padding: 8% 5%;
  }

  .heading__headerAbout {
    font-size: var(--fs-4);
    line-height: 3rem;
  }

  .description__headerAbout {
    font-weight: 500;
  }
}

@media screen and (max-width: 376px) {
  .container__headerAbout {
    height: auto;
  }

  .row__headerAbout {
    width: 100%;
    margin: auto;
    padding: 8% 5%;
  }

  .heading__headerAbout {
    font-size: var(--fs-4);
    line-height: 3rem;
  }

  .description__headerAbout {
    font-weight: 500;
  }
}

@media screen and (max-width: 360px) {
  .container__headerAbout {
    height: auto;
  }

  .row__headerAbout {
    width: 100%;
    margin: auto;
    padding: 8% 5%;
  }

  .heading__headerAbout {
    font-size: var(--fs-4);
    line-height: 3rem;
  }

  .description__headerAbout {
    font-weight: 500;
  }
}

/* Ends - Media Queries - Footer Component CSS  */
