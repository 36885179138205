.container__footer {
  background-color: var(--color-dark);
  color: var(--color-bg);
  padding-top: var(--space-xl);
}

.top__footer {
  display: flex;
  justify-content: space-around;
  padding-bottom: var(--space-xl);
}

.items__footer {
  display: flex;
  flex-direction: column;
  line-height: 2;
}

.items__footer h4 {
  margin-bottom: 15px;
}

.items__footer a:hover {
  color: var(--color-light);
}

.sm-icons {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-s);
}
.sm-icons img {
  width: var(--space-l);
  height: auto;
}

.bottom__footer {
  display: flex;
  align-items: center;
  gap: var(--space-m);
  background-color: var(--color-text);
  padding: var(--space-2xs);
  font-size: var(--fs-0);
}

.bottom__footer img {
  width: var(--img-size-16-64);
  height: auto;
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Footer Component CSS  */
@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
     .container__footer {
    padding-top: 10px;
  }
  .top__footer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: var(--space-l);
    padding-left: var(--space-3xl);
     padding-right: var(--space-3xl);
  }

  .items__footer h4 {
    margin-bottom: 15px;
  }

  .bottom__footer {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    font-size: 16px;
  }

  .bottom__footer img {
    /* display: none; */
    width: 90px;
    height: auto;
  }
}

@media screen and (max-width: 540px) {
  .container__footer {
    padding-top: 10px;
  }
  .top__footer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: var(--space-2xl);
  }

  .items__footer h4 {
    margin-bottom: 15px;
  }

  .bottom__footer {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    font-size: 16px;
  }

  .bottom__footer img {
    /* display: none; */
    width: 90px;
    height: auto;
  }
}

@media screen and (max-width: 450px) {
  .container__footer {
    padding-top: 10px;
  }
  .top__footer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: var(--space-xl);
  }

  .items__footer h4 {
    margin-bottom: 15px;
  }

  .bottom__footer {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    font-size: 16px;
  }

  .bottom__footer img {
    /* display: none; */
    width: 90px;
    height: auto;
  }
}

@media screen and (max-width: 375px) {
  .container__footer {
    padding-top: 10px;
  }
  .top__footer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: var(--space-xl);
  }

  .items__footer h4 {
    margin-bottom: 15px;
  }

  .bottom__footer {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    font-size: 16px;
  }

  .bottom__footer img {
    /* display: none; */
    width: 90px;
    height: auto;
  }
}

@media screen and (max-width: 360px) {
  .container__footer {
    padding-top: 10px;
  }
  .top__footer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: var(--space-xl);
  }

  .items__footer h4 {
    margin-bottom: 15px;
  }

  .bottom__footer {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    font-size: 16px;
  }

  .bottom__footer img {
    /* display: none; */
    width: 90px;
    height: auto;
  }
}

/* Ends - Media Queries - Footer Component CSS  */
