.container__Mission {
  height: auto;
  padding-top: 90px;
  padding-bottom: 90px;
}

.row__Mission {
  width: 1300px;
  margin: auto;
}

.top__Mission {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.description__Mission {
  width: 50ch;
}

.down__Mission {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  padding-top: 50px;
}

.card__Mission {
  flex: 300px;
}

.card__Mission {
  background-color: rgba(0, 113, 189, 0.7);
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 30px;
  border-radius: 15px;
}

.card__Mission p {
  font-size: var(--fs--1);
}

.icon--card__Mission {
  width: 64px;
  height: auto;
  margin-bottom: 15px;
}

.icon__Mission {
  width: 40px; /* Adjust size as needed */
  height: 40px;
  background-image: url("../../../public/images/icons/education.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-image 0.3s ease;
}

.icon__Mission:hover {
  background-image: url("../../../public/images/icons/education.gif");
  width: 64px; /* Adjust size as needed */
  height: 64px;
}



/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Mission Component CSS  */
@media screen and (max-width:1024px) {
    .container__Mission{
  height: auto;
  padding: 30px 0;
}

.row__Mission{
  width: 90%;
}

.top__Mission{
  text-align: center;
  padding: 10px 20px;

}

.description__Mission {
  width: 100%;
}

.down__Mission{
  padding: 20px;
  gap: 5px;
}
}

@media screen and (max-width:768px) {
    .container__Mission{
  height: auto;
  padding: 30px 0;
}

.row__Mission{
  width: 100%;
}

.top__Mission{
  text-align: center;
  padding: 10px 20px;

}

.description__Mission {
  width: 100%;
}

.down__Mission{
  padding: 20px;
  gap: 5px;
}
}

@media screen and (max-width:540px) {
  .container__Mission{
  height: auto;
  padding: 30px 0;
}

.row__Mission{
  width: 70%;
}

.top__Mission{
  text-align: center;
  padding: 10px 20px;

}

.description__Mission {
  width: 100%;
}

.down__Mission{
  padding: 20px;
  gap: 5px;
}
}

@media screen and (max-width:450px) {
  .container__Mission{
  height: auto;
  padding: 30px 0;
}

.row__Mission{
  width: 100%;
}

.top__Mission{
  text-align: center;
  padding: 10px 20px;

}

.description__Mission {
  width: 100%;
}

.down__Mission{
  padding: 20px;
  gap: 5px;
}
}

@media screen and (max-width:376px) {
  .container__Mission{
  height: auto;
  padding: 30px 0;
}

.row__Mission{
  width: 100%;
}

.top__Mission{
  text-align: center;
  padding: 10px 20px;

}

.description__Mission {
  width: 100%;
}

.down__Mission{
  padding: 20px;
  gap: 5px;
}
}

@media screen and (max-width:360px) {
.container__Mission{
  height: auto;
  padding: 30px 0;
}

.row__Mission{
  width: 100%;
}

.top__Mission{
  text-align: center;
  padding: 10px 20px;

}

.description__Mission {
  width: 100%;
}

.down__Mission{
  padding: 20px;
  gap: 5px;
}
}

/* Ends - Media Queries - Mission Component CSS  */
