@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");

/* Fonts */

.titillium-web-extralight {
  font-family: "Titillium Web", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.titillium-web-light {
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.titillium-web-regular {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.titillium-web-semibold {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.titillium-web-bold {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.titillium-web-black {
  font-family: "Titillium Web", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.titillium-web-extralight-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.titillium-web-light-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.titillium-web-regular-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.titillium-web-semibold-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.titillium-web-bold-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

:root {
  /* Colors */
  --color-main: #0e537f;
  --color-dark: #003860;
  --color-light: #0071bd;
  --color-accent: #f4bd98;
  --color-bg: #fefefe;
  --color-bg2: #dfdbdb;
  --color-text: #353233;
  --color-text-light: #bdbdbd;

 

      /* Types */
    --ff-body: "Titillium Web", sans-serif;

    --ff-title: "Titillium Web", sans-serif;
    --ff-top-title: "Bebas Neue", sans-serif;

  /* Font Sizes */
  /* Step -2: 12.5px → 12.8px */
  --fs--2: clamp(0.7813rem, 0.7775rem + 0.0188vw, 0.8rem);
  /* Step -1: 15px → 16px */
  --fs--1: clamp(0.9375rem, 0.925rem + 0.0625vw, 1rem);
  /* Step 0: 18px → 20px */
  --fs-0: clamp(1.125rem, 1.1rem + 0.125vw, 1.25rem);
  /* Step 1: 21.6px → 25px */
  --fs-1: clamp(1.35rem, 1.3075rem + 0.2125vw, 1.5625rem);
  /* Step 2: 25.92px → 31.25px */
  --fs-2: clamp(1.62rem, 1.5534rem + 0.3331vw, 1.9531rem);
  /* Step 3: 31.104px → 39.0625px */
  --fs-3: clamp(1.944rem, 1.8445rem + 0.4974vw, 2.4414rem);
  /* Step 4: 37.3248px → 48.8281px */
  --fs-4: clamp(2.3328rem, 2.189rem + 0.719vw, 3.0518rem);
  /* Step 5: 44.7898px → 61.0352px */
  --fs-5: clamp(2.7994rem, 2.5963rem + 1.0153vw, 3.8147rem);
  /* Step 6: 53.7477px → 76.2939px */
  --fs-6: clamp(3.3592rem, 3.0774rem + 1.4091vw, 4.7684rem);
  /* Step 7: 64.4973px → 95.3674px */
  --fs-7: clamp(4.0311rem, 3.6452rem + 1.9294vw, 5.9605rem);

      /* Spaces */
    /* Space 3xs: 5px → 5px */
    --space-3xs: clamp(0.3125rem, 0.3125rem + 0vw, 0.3125rem);
    /* Space 2xs: 9px → 10px */
    --space-2xs: clamp(0.5625rem, 0.55rem + 0.0625vw, 0.625rem);
    /* Space xs: 14px → 15px */
    --space-xs: clamp(0.875rem, 0.8625rem + 0.0625vw, 0.9375rem);
    /* Space s: 18px → 20px */
    --space-s: clamp(1.125rem, 1.1rem + 0.125vw, 1.25rem);
    /* Space m: 27px → 30px */
    --space-m: clamp(1.6875rem, 1.65rem + 0.1875vw, 1.875rem);
    /* Space l: 36px → 40px */
    --space-l: clamp(2.25rem, 2.2rem + 0.25vw, 2.5rem);
    /* Space xl: 54px → 60px */
    --space-xl: clamp(3.375rem, 3.3rem + 0.375vw, 3.75rem);
    /* Space 2xl: 72px → 80px */
    --space-2xl: clamp(4.5rem, 4.4rem + 0.5vw, 5rem);
    /* Space 3xl: 108px → 120px */
    --space-3xl: clamp(6.75rem, 6.6rem + 0.75vw, 7.5rem);

    /* One-up pairs */
    /* Space 3xs-2xs: 5px → 10px */
    --space-3xs-2xs: clamp(0.3125rem, 0.25rem + 0.3125vw, 0.625rem);
    /* Space 2xs-xs: 9px → 15px */
    --space-2xs-xs: clamp(0.5625rem, 0.4875rem + 0.375vw, 0.9375rem);
    /* Space xs-s: 14px → 20px */
    --space-xs-s: clamp(0.875rem, 0.8rem + 0.375vw, 1.25rem);
    /* Space s-m: 18px → 30px */
    --space-s-m: clamp(1.125rem, 0.975rem + 0.75vw, 1.875rem);
    /* Space m-l: 27px → 40px */
    --space-m-l: clamp(1.6875rem, 1.525rem + 0.8125vw, 2.5rem);
    /* Space l-xl: 36px → 60px */
    --space-l-xl: clamp(2.25rem, 1.95rem + 1.5vw, 3.75rem);
    /* Space xl-2xl: 54px → 80px */
    --space-xl-2xl: clamp(3.375rem, 3.05rem + 1.625vw, 5rem);
    /* Space 2xl-3xl: 72px → 120px */
    --space-2xl-3xl: clamp(4.5rem, 3.9rem + 3vw, 7.5rem);

    /* Custom pairs */
    /* Space s-3xl: 18px → 120px */
    --space-s-3xl: clamp(1.125rem, -0.15rem + 6.375vw, 7.5rem);


    /* letter spacing */

    --tracking: -0.05ch;
    --tracking-s: -0.075ch;
    --tracking-m: -0.1ch;
    --tracking-l: -0.125ch;
    --tracking-xl: -0.15ch;
    --tracking-2xl: -0.175ch;
    --tracking-3xl: -0.2ch;

    /* Image Size */

    --img-size-16-64: clamp(16px, -0.696px + 5.2174vi, 64px);
    --img-size-64-100: clamp(64px, 51.4784px + 3.913vi, 100px);
    --img-size-100-300: clamp(100px, 30.4352px + 21.7391vi, 300px);
    --img-size-300-500: clamp(300px, 230.4352px + 21.7391vi, 500px);
    --img-size-500-700: clamp(500px, 430.4352px + 21.7391vi, 700px);
    --img-size-700-1000: clamp(700px, 595.6528px + 32.6087vi, 1000px);
}
