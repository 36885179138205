.container__Blog{
    background-color: var(--color-bg);
}

.header-container__Blog{
    background-image: url('../../../public/images/events/cape.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    margin: 0 8px;
    border-radius: 10px;
    z-index: 0;
   
}

.row-header__Blog{
    background-color: rgba(244, 189, 152, 0.6);
    height: 100vh;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
     padding-top: 80px;
     z-index: 1;
}

.title-header__Blog{
    align-self: last baseline;
    padding: 50px;
    width: 50%;
}

.meta-header__Blog{
   align-self: last baseline;
   padding: 20px;

}