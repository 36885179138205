/* 
---------------------------------------------
      Global Styles 
---------------------------------------------
*/

body {
  background-color: var(--color-bg);
  font-size: var(--fs-0);
  font-family: var(--ff-body);
  font-weight: 200;

}

p {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
}

h1,
h2 {
  color: inherit;
  padding-top: var(--space-2xs);
  padding-bottom: var(--space-2xs);
}

h3,
h4,
h5,
h6 {
  padding-top: var(--space-3xs);
  padding-bottom: var(--space-3xs);
}

h1,
h2 {
  letter-spacing: var(--tracking);
}


blockquote:not([class]),
li,
dl {
  max-width: 70ch;
}

blockquote:not([class]) {
  font-family: var(--font-serif);
  font-size: var(--size-step-2);
}

blockquote:not([class]) p:last-of-type {
  font-family: var(--font-base);
  font-size: var(--size-step-1);
  font-weight: 400;
}

/* 
--------------------------------------
      line lengths 
--------------------------------------
*/

.text-length {
  max-width: 50ch;
}

.heading-length {
  max-width: 20ch;
}

/* 
--------------------------------------
      Base interactive elements 
--------------------------------------
*/
a {
  color: inherit;
  cursor: pointer;
  font-size: var();
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: var();
}

:focus {
  outline: 1px solid;
  outline-offset: 0.3ch;
}

:target {
  scroll-margin-top: 2ex;
}

/*-----------------------------------------------------------------
Base selection styles that invert whatever colors are rendered
-----------------------------------------------------------------
*/
::selection {
  background: var(--clr-accent);
  color: var(--clr-background);
}

/* 
---------------------------------------------
      Title Styles 
---------------------------------------------
*/

.title__top {
  font-family: "Bebas Neue", sans-serif;
  font-size: var(--fs-1);
  font-weight: 400;
  font-style: normal;
}

.title {
  font-family: "Titillium Web", sans-serif;
  font-size: var(--fs-7);
  font-weight: 800;
  font-style: normal;
}


.title2 {
  font-family: "Titillium Web", sans-serif;
  font-size: var(--fs-5);
  font-weight: 800;
  font-style: normal;
  line-height: 1.2;
}

.title3 {
  font-family: "Titillium Web", sans-serif;
  font-size: var(--fs-4);
  font-weight: 800;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: var(--tracking);
}

.title__section {
  font-family: "Titillium Web", sans-serif;
  font-size: var(--fs-4);
  font-weight: 800;
  font-style: normal;
  line-height: 1.15;
}
.subtitle__description {
  font-family: "Titillium Web", sans-serif;
  font-size: var(--fs-1);
  font-weight: 300;
  font-style: normal;
}

.description {
  font-family: "Titillium Web", sans-serif;
  font-size: var(--fs-0);
  font-weight: 300;
  font-style: normal;
}

.text{
    font-family: "Titillium Web", sans-serif;
  font-size: var(--fs-0);
  font-weight: 300;
  font-style: normal;
  line-height: 1.7;
}

.interface {
  font-family: "Titillium Web", sans-serif;
  font-size: var(--fs--1);
  font-weight: 600;
  font-style: normal;
}
/* 
--------------------------------------
      Utilities 
--------------------------------------
*/

.shadow{
  box-shadow: 3px -3px 4px 0px #7F646464;
}
.shadow1{
  box-shadow:  -3px 3px 4px 0px #7F646464
}

/* 
FLOW UTILITY
Like the Every Layout stack: https://every-layout.dev/layouts/stack/
Info about this implementation: https://piccalil.li/quick-tip/flow-utility/ 
*/
.flow > * + * {
  margin-top: var(--flow-space, 1em);
}

/**
 * REGION 
 * Add consistent vertical padding to create regions of content 
 * Can either be configured by setting --region-space or use a default from the space scale
 */
.region {
  padding-top: var(--region-space, var(--space-l-xl));
  padding-bottom: var(--region-space, var(--space-l-xl));
}

/**
 * WRAPPER
 * Sets a max width, adds a consistent gutter and horizontally
 * centers the contents
 * Info: https://piccalil.li/quick-tip/use-css-clamp-to-create-a-more-flexible-wrapper-utility/
 */
.wrapper {
  max-width: var(--wrapper-max-width, 85rem);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  position: relative;
}


/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Footer Component CSS  */
@media screen and (max-width: 3840px){}
@media screen and (max-width: 1920px){}
@media screen and (max-width: 1366px){}
@media screen and (max-width: 1536px){}
@media screen and (max-width: 1440px){}
@media screen and (max-width: 1280px){}
@media screen and (max-width:1024px) {}

@media screen and (max-width:768px) {}

@media screen and (max-width:540px) {}

@media screen and (max-width:450px) {}

@media screen and (max-width:375px) {}

@media screen and (max-width:360px) {}

/* Ends - Media Queries - Footer Component CSS  */