.container__Overview {
  background-image: url("../../../public/images/bgs/story.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 80px;
}

.row__Overview {
  width: 1300px;
  margin: auto;
  padding: 30px;
}

.title__Overview {
  background-color: rgba(14, 83, 127, 0.5);
  width: 500px;
  height: 100%;
  padding: 50px;
  border-radius: 15px;
}

.stickyStuff {
  padding-top: 1px;
  padding-bottom: 1px;
  background-color: var(--color-bg);
  -webkit-box-shadow: 0 14px 14px -14px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 14px 14px -14px rgba(0, 0, 0, 0.75);
  box-shadow: 0 14px 14px -14px rgba(0, 0, 0, 0.75);
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.container__sidebar {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  width: 1300px;
  margin: auto;
  padding: 30px;
}

.container__sidebar > :first-child {
  flex-basis: 300px;
  flex-grow: 1;
  padding: 20px;
  border-right: solid 3px var(--color-text);
}

.container__sidebar > :last-child {
  flex-basis: 0;
  flex-grow: 999;
  min-inline-size: 50%;
  padding: 20px;
}

.sideMenu {
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
  left: 0;
  padding-top: 2px;
  padding-bottom: 50px;
  height: auto;
  font-size: var(--fs-1);
  font-weight: 500;
  line-height: 2;
}

.heading__sideMenu {
  font-size: var(--fs-2);
}

.Link__sideMenu a:hover {
  text-decoration: underline;
  font-weight: 700;
}

.row_grid__omb {
  display: flex;
  justify-content: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  padding: 50px 10px;
}

.card__omb {
  background-color: rgba(189, 189, 189, 0.2);
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 20px;
  border-radius: 15px;
  z-index: 1;
}

.card__omb img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.button-card__hub {
  width: 100%;
}

/*
-------------------------------------
        Page Specific CSS
-------------------------------------
*/

/* Hub Page Specific CSS */

.container__hub-header {
  background-image: url("../../../public/images/bgs/story.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 80px;
  padding-bottom: 30px;
}

.row__hub-header {
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 1300px;
  height: 100%;
  margin: auto;
  padding: 30px;
}

.title__hub-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: rgba(14, 83, 127, 0.5);
  width: 500px;
  height: 100%;
  padding: 50px;
  border-radius: 15px;
}

@media screen and (max-width: 1024px) {
  .container__hub-header {
    background-image: url("../../../public/images/bgs/story.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    padding-top: 100px;
  }
  .row__hub-header {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    height: auto;
    margin: auto;
    padding: 10px;
  }

  .title__hub-header {
    background-color: rgba(14, 83, 127, 0.5);
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 15px;
    padding: 20px;
  }

  .headerImage {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .container__hub-header {
    background-image: url("../../../public/images/bgs/story.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    padding-top: 100px;
  }
  .row__hub-header {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    height: auto;
    margin: auto;
    padding: 10px;
  }

  .title__hub-header {
    background-color: rgba(14, 83, 127, 0.5);
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 15px;
    padding: 20px;
  }

  .headerImage {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .container__hub-header {
    background-image: url("../../../public/images/bgs/story.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    padding-top: 100px;
  }
  .row__hub-header {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    height: auto;
    margin: auto;
    padding: 10px;
  }

  .title__hub-header {
    background-color: rgba(14, 83, 127, 0.5);
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 15px;
    padding: 20px;
  }

  .headerImage {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .container__hub-header {
    background-image: url("../../../public/images/bgs/story.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    padding-top: 100px;
  }
  .row__hub-header {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    height: auto;
    margin: auto;
    padding: 10px;
  }

  .title__hub-header {
    background-color: rgba(14, 83, 127, 0.5);
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 15px;
    padding: 20px;
  }

  .headerImage {
    display: none;
  }
}

@media screen and (max-width: 376px) {
  .container__hub-header {
    background-image: url("../../../public/images/bgs/story.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    padding-top: 100px;
  }
  .row__hub-header {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    height: auto;
    margin: auto;
    padding: 10px;
  }

  .title__hub-header {
    background-color: rgba(14, 83, 127, 0.5);
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 15px;
    padding: 20px;
  }

  .headerImage {
    display: none;
  }
}

@media screen and (max-width: 360px) {
  .container__hub-header {
    background-image: url("../../../public/images/bgs/story.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    padding-top: 100px;
  }
  .row__hub-header {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    height: auto;
    margin: auto;
    padding: 10px;
  }

  .title__hub-header {
    background-color: rgba(14, 83, 127, 0.5);
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 15px;
    padding: 20px;
  }

  .headerImage {
    display: none;
  }
}

/* Participate Page Specific CSS */

.container-header__Participate {
  background-image: url("../../../public/images/resources/participate.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-header__Participate {
  background-color: rgba(254, 254, 254, 0.4);
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  width: 70%;
  height: 400px;
  margin: auto;
  padding: 50px 80px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 35px;
}

.description__Participate {
  width: 80%;
  font-weight: 600;
}

.button__Participate {
  margin-top: 20px;
}

.features__Participate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  padding: 50px 10px;
}

.card__Participate {
  flex-basis: 300px;
  flex-grow: 1;
 background-color: rgba(189, 189, 189, 0.2);
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 15px;
  border-radius: 15px;
  z-index: 1;
}

.card__Participate img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.button-card__hub {
  width: 100%;
}


@media screen and (max-width:1024px) {
    .container-header__Participate{
    height: auto;
    padding-top: 80px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .row-header__Participate{
    width: 100%;
    height: auto;
    padding: 30px 20px;
  }

  .description__Participate{
    width: 100%;
  }

  .button__Participate {
    display: none;
  }
}

@media screen and (max-width:768px) {
    .container-header__Participate{
    height: auto;
    padding-top: 80px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .row-header__Participate{
    width: 100%;
    height: auto;
    padding: 30px 20px;
  }

  .description__Participate{
    width: 100%;
  }

  .button__Participate {
    display: none;
  }
}

@media screen and (max-width:540px) {
    .container-header__Participate{
    height: auto;
    padding-top: 80px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .row-header__Participate{
    width: 100%;
    height: auto;
    padding: 30px 20px;
  }

  .description__Participate{
    width: 100%;
  }

  .button__Participate {
    display: none;
  }
}

@media screen and (max-width:450px) {
    .container-header__Participate{
    height: auto;
    padding-top: 80px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .row-header__Participate{
    width: 100%;
    height: auto;
    padding: 30px 20px;
  }

  .description__Participate{
    width: 100%;
  }

  .button__Participate {
    display: none;
  }
}

@media screen and (max-width:375px) {
    .container-header__Participate{
    height: auto;
    padding-top: 80px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .row-header__Participate{
    width: 100%;
    height: auto;
    padding: 30px 20px;
  }

  .description__Participate{
    width: 100%;
  }

  .button__Participate {
    display: none;
  }
}

@media screen and (max-width:360px) {
  .container-header__Participate{
    height: auto;
    padding-top: 80px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .row-header__Participate{
    width: 100%;
    height: auto;
    padding: 30px 20px;
  }

  .description__Participate{
    width: 100%;
  }

  .button__Participate {
    display: none;
  }
}

/* Learn Page Specific CSS */

.container-header__Learn {
  background: #005aa7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #fffde4,
    #005aa7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #fffde4,
    #005aa7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row-header__Learn {
  width: 1300px;
  margin: auto;
  padding: 30px;
  display: flex;
}

.title-header__Learn {
   background-color: rgba(14, 83, 127, 0.5);
  width: 100%;
  height: 100%;
  padding: 50px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.description-header__Learn{
  width: 60%;
  font-weight: 600;
}

.features__Learn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  padding: 50px 10px;
}

.card__Learn {
  flex-basis: 300px;
  flex-grow: 1;
  background-color: rgba(189, 189, 189, 0.2);
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
}

@media screen and (max-width:1024px) {
    .container-header__Learn{
    height: auto;
  }
  .row-header__Learn{
    width: 100%;
    padding: 10px;
  }

  .title-header__Learn{
    padding: 30px 15px;
  }

  .description-header__Learn{
    width: 100%;
  }

  .button__learn{
    display: none;
  }
}

@media screen and (max-width:768px) {
    .container-header__Learn{
    height: auto;
  }
  .row-header__Learn{
    width: 100%;
    padding: 10px;
  }

  .title-header__Learn{
    padding: 30px 15px;
  }

  .description-header__Learn{
    width: 100%;
  }

  .button__learn{
    display: none;
  }
}

@media screen and (max-width:540px) {
    .container-header__Learn{
    height: auto;
  }
  .row-header__Learn{
    width: 100%;
    padding: 10px;
  }

  .title-header__Learn{
    padding: 30px 15px;
  }

  .description-header__Learn{
    width: 100%;
  }

  .button__learn{
    display: none;
  }
}

@media screen and (max-width:450px) {
    .container-header__Learn{
    height: auto;
  }
  .row-header__Learn{
    width: 100%;
    padding: 10px;
  }

  .title-header__Learn{
    padding: 30px 15px;
  }

  .description-header__Learn{
    width: 100%;
  }

  .button__learn{
    display: none;
  }
}

@media screen and (max-width:376px) {
    .container-header__Learn{
    height: auto;
  }
  .row-header__Learn{
    width: 100%;
    padding: 10px;
  }

  .title-header__Learn{
    padding: 30px 15px;
  }

  .description-header__Learn{
    width: 100%;
  }

  .button__learn{
    display: none;
  }
}

@media screen and (max-width:360px) {
  .container-header__Learn{
    height: auto;
  }
  .row-header__Learn{
    width: 100%;
    padding: 10px;
  }

  .title-header__Learn{
    padding: 30px 15px;
  }

  .description-header__Learn{
    width: 100%;
  }

  .button__learn{
    display: none;
  }
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Learn Page CSS  */

@media screen and (max-width: 1024px) {
  .container__Overview {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .row__Overview {
    width: 100%;
    height: auto;
    padding: 80px 2px;
  }

  .title__Overview {
    width: 100%;
    height: auto;
    padding: 50px;
  }

  .container__sidebar {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    margin: auto;
    padding: 30px;
  }

  .container__sidebar > :first-child,
  .container__sidebar > :last-child {
    padding: 15px;
  }

  .card__omb {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .container__Overview {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .row__Overview {
    width: 100%;
    height: auto;
    padding: 80px 2px;
  }

  .title__Overview {
    width: 100%;
    height: auto;
    padding: 50px;
  }

  .container__sidebar {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    margin: auto;
    padding: 30px;
  }

  .container__sidebar > :first-child,
  .container__sidebar > :last-child {
    padding: 15px;
  }

  .container__sidebar > :first-child {
    border-right: none;
  }

  .card__omb {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .container__Overview {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .row__Overview {
    width: 100%;
    height: auto;
    padding: 80px 2px;
  }

  .title__Overview {
    width: 100%;
    height: auto;
    padding: 50px;
  }

  .container__sidebar {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    margin: auto;
    padding: 10px;
  }

  .container__sidebar > :first-child,
  .container__sidebar > :last-child {
    padding: 15px;
  }

  .container__sidebar > :first-child {
    border-right: none;
  }

  .card__omb {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .container__Overview {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .row__Overview {
    width: 100%;
    height: auto;
    padding: 80px 2px;
  }

  .title__Overview {
    width: 100%;
    height: auto;
    padding: 50px;
  }

  .container__sidebar {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    margin: auto;
    padding: 10px;
  }

  .container__sidebar > :first-child,
  .container__sidebar > :last-child {
    padding: 10px;
  }

  .container__sidebar > :first-child {
    border-right: none;
  }

  .card__omb {
    width: 100%;
  }
}

@media screen and (max-width: 376px) {
  .container__Overview {
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .row__Overview {
    width: 100%;
    height: auto;
    padding: 80px 2px;
  }

  .title__Overview {
    width: 100%;
    height: auto;
    padding: 50px;
  }

  .container__sidebar {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    margin: auto;
    padding: 10px;
  }

  .container__sidebar > :first-child,
  .container__sidebar > :last-child {
    padding: 5px;
  }

  .container__sidebar > :first-child {
    border-right: none;
  }

  .card__omb {
    width: 100%;
  }
}

@media screen and (max-width: 360px) {
  .container__Overview {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .row__Overview {
    width: 100%;
    height: auto;
    padding: 80px 2px;
  }

  .title__Overview {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .container__sidebar {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    margin: auto;
    padding: 5px;
  }

  .container__sidebar > :first-child,
  .container__sidebar > :last-child {
    padding: 15px;
  }

  .container__sidebar > :first-child {
    border-right: none;
  }

  .card__omb {
    width: 100%;
  }
}
/* Ends - Media Queries - Learn Page CSS  */
