.container__WhatWeDo {
  height: 100vh;
}

.row__WhatWeDo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 20px;
  width: 1300px;
  margin: auto;
  height: 100vh;
}

.left__WhatWeDo,
.right__WhatWeDo {
  flex: 1;
}

.left__WhatWeDo {
  background-color: var(--color-text);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  border-radius: 15px;
}

.leftImage {
  width: 70%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.right__WhatWeDo {
  padding: 10px 50px;
}

.heading__WhatWeDo {
  margin-bottom: 10px;
}
.description__WhatWeDo {
  width: 40ch;
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - WhatWeDo Component CSS  */
@media screen and (max-width: 1024px) {
      .container__WhatWeDo {
    height: 1100px;
    padding: 70px 5px;
  }
  .row__WhatWeDo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem;
    width: 70%;
    padding: 20px;
  }

  .right__WhatWeDo {
    width: 100%;
    margin: auto;
    padding: 10px 10px;
  }

  .description__WhatWeDo {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .container__WhatWeDo {
    height: 1000px;
    padding: 70px 5px;
  }
  .row__WhatWeDo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem;
    width: 70%;
    padding: 20px;
  }

  .right__WhatWeDo {
    width: 100%;
    margin: auto;
    padding: 10px 10px;
  }

  .description__WhatWeDo {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .container__WhatWeDo {
    height: auto;
    padding: 70px 5px;
  }
  .row__WhatWeDo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem;
    width: 100%;
    padding: 20px;
  }

  .right__WhatWeDo {
    width: 100%;
    margin: auto;
    padding: 10px 10px;
  }

  .description__WhatWeDo {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .container__WhatWeDo {
    height: auto;
    padding: 70px 5px;
  }
  .row__WhatWeDo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem;
    width: 100%;
    padding: 20px;
  }

  .right__WhatWeDo {
    width: 100%;
    margin: auto;
    padding: 10px 10px;
  }

  .description__WhatWeDo {
    width: 100%;
  }
}

@media screen and (max-width: 376px) {
  .container__WhatWeDo {
    height: auto;
    padding: 70px 5px;
  }
  .row__WhatWeDo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem;
    width: 100%;
    padding: 20px;
  }

  .right__WhatWeDo {
    width: 100%;
    margin: auto;
    padding: 10px 10px;
  }

  .description__WhatWeDo {
    width: 100%;
  }
}

@media screen and (max-width: 360px) {
  .container__WhatWeDo {
    height: auto;
    padding: 50px 5px;
  }
  .row__WhatWeDo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem;
    width: 100%;
    padding: 20px;
  }

  .right__WhatWeDo {
    width: 100%;
    margin: auto;
    padding: 10px 10px;
  }

  .description__WhatWeDo {
    width: 100%;
  }
}

/* Ends - Media Queries - WhatWeDo Component CSS  */
