.container__Resources {
  position: relative;
  z-index: 1;
}

.BgImage__Resources {
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 2;
}

.container__Resources,
.BgImage__Resources {
  height: auto;
}

.row__Resources {
  position: absolute;
  top: 5%;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 90px;
  padding: 80px;
}

.container__Resources span {
  display: block;
}

.left_column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.left_item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background-color: rgba(53, 50, 51, 0.5);
  /* background-color: rgba(223, 219, 219, 0.5); */
  backdrop-filter: blur(2px);
  padding: 50px 30px;
  border-radius: 10px;
}

.heading_item {
  text-align: center;
}
.text_item {
  /* font-size: var(--fs--1); */
}

.right_column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  text-align: center;
}

.cta__resources {
  margin: auto;
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Resources Component CSS  */
@media screen and (max-width: 1366px){
  .container__Resources,.BgImage__Resources{
    height: 900px;
  }
.row__Resources {
  position: absolute;
  top: 1%;
}
}
@media screen and (max-width: 1024px) {
  .container__Resources,
  .BgImage__Resources {
    height: 1200px;
  }

  .row__Resources {
    position: absolute;
    top: 4%;
    width: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 20px;
  }

  .left_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .left_item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    width: 60%;
    margin: auto;
  }
  .right_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .container__Resources,
  .BgImage__Resources {
    height: 1350px;
  }

  .row__Resources {
    position: absolute;
    top: 4%;
    width: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 20px;
  }

  .left_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .left_item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }
  .right_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    text-align: center;
  }
}

@media screen and (max-width: 540px) {
  .container__Resources,
  .BgImage__Resources {
    height: 1400px;
  }

  .row__Resources {
    position: absolute;
    top: 4%;
    width: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 20px;
  }

  .left_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .left_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    text-align: center;
     width: 90%;
  }
  .right_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .container__Resources,
  .BgImage__Resources {
    height: 1600px;
  }

  .row__Resources {
    position: absolute;
    top: 4%;
    width: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 20px;
  }

  .left_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .left_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    text-align: center;
     width: 90%;
  }
  .right_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    text-align: center;
  }
}

@media screen and (max-width: 376px) {
  .container__Resources,
  .BgImage__Resources {
    height: 1650px;
  }

  .row__Resources {
    position: absolute;
    top: 4%;
    width: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 20px;
  }

  .left_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .left_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    text-align: center;
     width: 90%;
  }
  .right_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    text-align: center;
  }
}

@media screen and (max-width: 360px) {
  .container__Resources,
  .BgImage__Resources {
    height: 1700px;
  }

  .row__Resources {
    position: absolute;
    top: 4%;
    width: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 20px;
  }

  .left_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .left_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    text-align: center;
     width: 90%;
  }
  .right_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    text-align: center;
  }
}

/* Ends - Media Queries - Resources Component CSS  */
