.container__Events {
  height: auto;
  padding: 60px 20px;
  background: #12c2e9; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #0e537f,
    #dfdbdb,
    #0071bd
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #0e537f,
    #dfdbdb,
    #0071bd
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.title__Events {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 15px;
  padding: 30px;
  width: 50%;
  margin: auto;
}

.cards__Events {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 50px;
}

.card_item {
  flex: 1;
  border-radius: 15px;
  background-color: rgba(254, 254, 254, 0.8);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 25px 15px;
}

.card_item img {
  width: 95%;
  height: auto;
  margin: auto;
  border-radius: 5px;
}

.item_category,
.item_heading,
.item_description {
  padding-left: 15px;
}

.smallAction {
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.item_category {
  font-family: var(--ff-top-title);
  margin-top: 15px;
}

.item_heading {
  font-family: var(--ff-title);
  font-size: var(--fs-1);
  font-weight: 700;
  padding-left: 15px;
}

.item_description {
  font-size: var(--fs-0);
  padding-left: 15px;
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Footer Component CSS  */
@media screen and (max-width: 1024px) {
    .title__Events {
    width: 65%;
  }
}

@media screen and (max-width: 768px) {
   .container__Events {
    height: auto;
    padding: 60px 30px;
  }

  .title__Events {
    width: 95%;
  }

  .cards__Events {
    display: flex;
    flex-direction: column;
    padding: 5px;
  }

  .item_heading {
    font-size: var(--fs-4);
  }
}

@media screen and (max-width: 540px) {
   .container__Events {
    height: auto;
    padding: 30px 5px;
  }

  .title__Events {
    width: 95%;
  }

  .cards__Events {
    display: flex;
    flex-direction: column;
    padding: 30px;
  }

  .item_heading {
    font-size: var(--fs-4);
  }
}

@media screen and (max-width: 450px) {
   .container__Events {
    height: auto;
    padding: 30px 5px;
  }

  .title__Events {
    width: 95%;
  }

  .cards__Events {
    display: flex;
    flex-direction: column;
    padding: 5px;
  }

  .item_heading {
    font-size: var(--fs-4);
  }
}

@media screen and (max-width: 376px) {
  .container__Events {
    height: auto;
    padding: 30px 5px;
  }

  .title__Events {
    width: 95%;
  }

  .cards__Events {
    display: flex;
    flex-direction: column;
    padding: 5px;
  }

  .item_heading {
    font-size: var(--fs-4);
  }
}

@media screen and (max-width: 360px) {
  .container__Events {
    height: auto;
    padding: 30px 5px;
  }

  .title__Events {
    width: 95%;
  }

  .cards__Events {
    display: flex;
    flex-direction: column;
    padding: 5px;
  }

  .item_heading {
    font-size: var(--fs-4);
  }
}

/* Ends - Media Queries - Footer Component CSS  */
