.container__Vision {
  background-color: var(--color-bg2);
  height: 100vh;
}

.row__Vision {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  width: 1300px;
  height: 100vh;
  padding: 20px;
}

.left__Vision,
.right__Vision {
  flex: 1;
}

.right__Vision {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  border-radius: 15px;
}

.rightImage__Vision {
  width: 70%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.left__Vision {
  padding: 10px 50px;
}

.heading__Vision {
  margin-bottom: 10px;
}
.description__Vision {
  width: 40ch;
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Footer Component CSS  */
@media screen and (max-width: 1024px) {
      .container__Vision {
    height: auto;
    padding: 20px;
  }

  .row__Vision {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 30px;
    width: 70%;
    height: auto;
    padding: 10px;
    margin: auto;
  }

  .left__Vision,
.right__Vision {
  flex: 1;
}

.left__Vision {
  padding: 10px 10px;
}

.right__Vision {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 15px;
}

  .description__Vision{
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
      .container__Vision {
    height: auto;
    padding: 20px;
  }

  .row__Vision {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 30px;
    width: 70%;
    height: auto;
    padding: 10px;
    margin: auto;
  }

  .left__Vision,
.right__Vision {
  flex: 1;
}

.left__Vision {
  padding: 10px 10px;
}

.right__Vision {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 15px;
}

  .description__Vision{
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
      .container__Vision {
    height: auto;
    padding: 20px;
  }

  .row__Vision {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 30px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .left__Vision,
.right__Vision {
  flex: 1;
}

.left__Vision {
  padding: 10px 10px;
}

.right__Vision {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 15px;
}

  .description__Vision{
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
      .container__Vision {
    height: auto;
    padding: 20px;
  }

  .row__Vision {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 30px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .left__Vision,
.right__Vision {
  flex: 1;
}

.left__Vision {
  padding: 10px 10px;
}

.right__Vision {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 15px;
}

  .description__Vision{
    width: 100%;
  }
}

@media screen and (max-width: 376px) {
      .container__Vision {
    height: auto;
    padding: 20px;
  }

  .row__Vision {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 30px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .left__Vision,
.right__Vision {
  flex: 1;
}

.left__Vision {
  padding: 10px 10px;
}

.right__Vision {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 15px;
}

  .description__Vision{
    width: 100%;
  }
}

@media screen and (max-width: 360px) {
  .container__Vision {
    height: auto;
    padding: 20px;
  }

  .row__Vision {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 30px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .left__Vision,
.right__Vision {
  flex: 1;
}

.left__Vision {
  padding: 10px 10px;
}

.right__Vision {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 15px;
}

  .description__Vision{
    width: 100%;
  }
}

/* Ends - Media Queries - Footer Component CSS  */
