@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);

/* Header Section  */
.container-header__Academy {
  background-color: #5ec5f2;
}

.row-header__Academy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 81.25rem;
  height: 100vh;
  margin: auto;
  padding: 5rem 1.875rem;
  gap: 2.5rem;
}

.Image-container__Academy {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.left-header__Academy,
.right-header__Academy {
  flex: 1;
}

.left-header__Academy{
  display: flex;
  gap: 1.25rem;
  flex-direction: column;
}

/* Main Section */


/* Courses Section */
.container-courses__Academy{
  padding: 3.125rem 1.25rem;
}
.heading-courses__Academy{
  width: 70%;
  height: auto;
  margin: auto;
  text-align: center;
}

.description-courses__Academy{
width: 70%;
margin: auto;
}

.row-courses__Academy{
  width: 81.25rem;
  height: auto;
  margin: auto;
  padding: 3.125rem .625rem;
  display: flex;
  justify-content: center;
  gap: 3.125rem;
}

.course{
  flex: 1;
  background-color: var(--color-bg);
  padding: .9375rem;
  border-radius: .9375rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: .625rem;
}

.ImageCourse{
  width: 100%;
  height: auto;
  object-position: center;
  object-fit: cover;
  border-radius: .625rem;
}

.course h3{
  font-family: var(--ff-title);
  font-size: var(--fs-1);
}

.course p{
  font-size: var(--fs--1);
}

.cta_courses{
  display: flex;
  justify-content: center;
}

.cta_button_courses{
  width: 100%;
}

/* Modes Of Training  */
.container__how-it-works{
  padding: 3.125rem 1.25rem;
}

.row__how-it-works{
  width: 81.25rem;
  height: auto;
  margin: auto;
  padding: 1.25rem;
}

.title__how-it-works{
  width: 70%;
  margin: auto;
  text-align: center;
}

.ways-to-train{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3.125rem;
  padding: 3.125rem 1.25rem;
}

.Icon__modes{
  width: 128px;
  height: auto;
}

.modes{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px;
}

.modes p{
  width: 70%;
}


/* Testimonials */

.testimonials{
  background-color: var(--color-bg);
  padding: 3.125rem 1.25rem;
}

.title_testimonials__Academy{
  width: 70%;
  margin: auto;
  text-align: center;
}

.description-testimonials__Academy{
  width: 70%;
  margin: auto;
}
.container_testimonials__Academy{
  background-color: var(--color-bg);
  height: 60vh;
}


.slider {
  height: 100%;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.slider__nav {
  width: 12px;
  height: 12px;
  margin: 2rem 12px;
  border-radius: 50%;
  z-index: 10;
  outline: 6px solid #ccc;
  outline-offset: -6px;
  box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.slider__nav:checked {
  -webkit-animation: check 0.4s linear forwards;
          animation: check 0.4s linear forwards;
}
.slider__nav:checked:nth-of-type(1) ~ .slider__inner {
  left: 0%;
}
.slider__nav:checked:nth-of-type(2) ~ .slider__inner {
  left: -100%;
}
.slider__nav:checked:nth-of-type(3) ~ .slider__inner {
  left: -200%;
}
.slider__nav:checked:nth-of-type(4) ~ .slider__inner {
  left: -300%;
}
.slider__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 400%;
  height: 100%;
  -webkit-transition: left 0.4s;
  transition: left 0.4s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
}
.slider__contents {
  height: 100%;
  padding: 2rem;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-flex-flow: column nowrap;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.slider__image {
  font-size: 2.7rem;
      color: #2196F3;
}
.slider__caption {
  font-weight: 500;
  margin: 2rem 0 1rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
}
.slider__txt {
  color: inherit;
  margin-bottom: 3rem;
  max-width: 300px;
}

@-webkit-keyframes check {
  50% {
    outline-color: #333;
    box-shadow: 0 0 0 12px #333, 0 0 0 36px rgba(51, 51, 51, 0.2);
  }
  100% {
    outline-color: #333;
    box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
  }
}

@keyframes check {
  50% {
    outline-color: #333;
    box-shadow: 0 0 0 12px #333, 0 0 0 36px rgba(51, 51, 51, 0.2);
  }
  100% {
    outline-color: #333;
    box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
  }
}


/* CTA */

.container_join{
 padding: 50px 20px;
}

.row__join{
  width: 1300px;
   height: 70vh;
   margin: auto;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.bottom__join{
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 30px 0;
}

.description__join{
  width: 60%;
  text-align: center;
}


/* Page Utilities */

.pageColor {
  background-color: #5ec5f2;
}


.bg1 {
  background-color: #f4bd98;
  background-image: -webkit-linear-gradient(0deg, #f4bd98 0%, #5ec5f2 100%);
  background-image: -moz-linear-gradient(0deg, #f4bd98 0%, #5ec5f2 100%);
  background-image: -o-linear-gradient(0deg, #f4bd98 0%, #5ec5f2 100%);
  background-image: linear-gradient(0deg, #f4bd98 0%, #5ec5f2 100%);
  height: 100vh;
}


.bg2{
  background: #2980B9;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #FFFFFF, #6DD5FA, #2980B9);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #FFFFFF, #6DD5FA, #2980B9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}


/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - Academy Page CSS  */
@media screen and (max-width:1024px) {
   .row-header__Academy{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: auto;
    gap: 30px;
    padding-top: 5.625rem;
    padding-bottom: .625rem;
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .button_header__Academy{
    margin: auto;
  }

  .heading-header__Academy{
    font-size: var(--fs-4);
  }

  .row-courses__Academy,.row__how-it-works,.row__join{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .container-courses__Academy{
    padding: .625rem .625rem;
  }
  
  .description-courses__Academy{
    width: 100%;
  }

  .container__how-it-works{
    padding: 1.25rem .625rem;
  }

  .title__how-it-works{
    width: 100%;
  }

  .ways-to-train{
    display: flex;
    flex-direction: column;
    gap: .625rem;
    padding: .625rem .625rem;
  }

  .modes{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .625rem;
  }

  .testimonials{
    padding: .9375rem .625rem;
  }

  .description-testimonials__Academy{
    width: 100%;
  }

  .slider__contents{
    padding: 10px;
    height: auto;
  }

  .bottom__join{
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width:768px) {
   .row-header__Academy{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: auto;
    gap: 30px;
    padding-top: 5.625rem;
    padding-bottom: .625rem;
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .button_header__Academy{
    margin: auto;
  }

  .heading-header__Academy{
    font-size: var(--fs-4);
  }

  .row-courses__Academy,.row__how-it-works,.row__join{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .container-courses__Academy{
    padding: .625rem .625rem;
  }
  
  .description-courses__Academy{
    width: 100%;
  }

  .container__how-it-works{
    padding: 1.25rem .625rem;
  }

  .title__how-it-works{
    width: 100%;
  }

  .ways-to-train{
    display: flex;
    flex-direction: column;
    gap: .625rem;
    padding: .625rem .625rem;
  }

  .modes{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .625rem;
  }

  .testimonials{
    padding: .9375rem .625rem;
  }

  .description-testimonials__Academy{
    width: 100%;
  }

  .slider__contents{
    padding: 10px;
    height: auto;
  }

  .bottom__join{
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width:540px) {
   .row-header__Academy{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: auto;
    gap: 30px;
    padding-top: 5.625rem;
    padding-bottom: .625rem;
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .button_header__Academy{
    margin: auto;
  }

  .heading-header__Academy{
    font-size: var(--fs-4);
  }

  .row-courses__Academy,.row__how-it-works,.row__join{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .container-courses__Academy{
    padding: .625rem .625rem;
  }
  
  .description-courses__Academy{
    width: 100%;
  }

  .container__how-it-works{
    padding: 1.25rem .625rem;
  }

  .title__how-it-works{
    width: 100%;
  }

  .ways-to-train{
    display: flex;
    flex-direction: column;
    gap: .625rem;
    padding: .625rem .625rem;
  }

  .modes{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .625rem;
  }

  .testimonials{
    padding: .9375rem .625rem;
  }

  .description-testimonials__Academy{
    width: 100%;
  }

  .slider__contents{
    padding: 10px;
    height: auto;
  }

  .bottom__join{
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width:450px) {
   .row-header__Academy{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: auto;
    gap: 30px;
    padding-top: 5.625rem;
    padding-bottom: .625rem;
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .button_header__Academy{
    margin: auto;
  }

  .heading-header__Academy{
    font-size: var(--fs-4);
  }

  .row-courses__Academy,.row__how-it-works,.row__join{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .container-courses__Academy{
    padding: .625rem .625rem;
  }
  
  .description-courses__Academy{
    width: 100%;
  }

  .container__how-it-works{
    padding: 1.25rem .625rem;
  }

  .title__how-it-works{
    width: 100%;
  }

  .ways-to-train{
    display: flex;
    flex-direction: column;
    gap: .625rem;
    padding: .625rem .625rem;
  }

  .modes{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .625rem;
  }

  .testimonials{
    padding: .9375rem .625rem;
  }

  .description-testimonials__Academy{
    width: 100%;
  }

  .slider__contents{
    padding: 10px;
    height: auto;
  }

  .bottom__join{
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width:376px) {
    .row-header__Academy{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: auto;
    gap: 30px;
    padding-top: 5.625rem;
    padding-bottom: .625rem;
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .button_header__Academy{
    margin: auto;
  }

  .heading-header__Academy{
    font-size: var(--fs-4);
  }

  .row-courses__Academy,.row__how-it-works,.row__join{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .container-courses__Academy{
    padding: .625rem .625rem;
  }
  
  .description-courses__Academy{
    width: 100%;
  }

  .container__how-it-works{
    padding: 1.25rem .625rem;
  }

  .title__how-it-works{
    width: 100%;
  }

  .ways-to-train{
    display: flex;
    flex-direction: column;
    gap: .625rem;
    padding: .625rem .625rem;
  }

  .modes{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .625rem;
  }

  .testimonials{
    padding: .9375rem .625rem;
  }

  .description-testimonials__Academy{
    width: 100%;
  }

  .slider__contents{
    padding: 10px;
    height: auto;
  }

  .bottom__join{
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width:360px) {
  .row-header__Academy{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: auto;
    gap: 30px;
    padding-top: 5.625rem;
    padding-bottom: .625rem;
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .button_header__Academy{
    margin: auto;
  }

  .heading-header__Academy{
    font-size: var(--fs-4);
  }

  .row-courses__Academy,.row__how-it-works,.row__join{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .container-courses__Academy{
    padding: .625rem .625rem;
  }
  
  .description-courses__Academy{
    width: 100%;
  }

  .container__how-it-works{
    padding: 1.25rem .625rem;
  }

  .title__how-it-works{
    width: 100%;
  }

  .ways-to-train{
    display: flex;
    flex-direction: column;
    gap: .625rem;
    padding: .625rem .625rem;
  }

  .modes{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .625rem;
  }

  .testimonials{
    padding: .9375rem .625rem;
  }

  .description-testimonials__Academy{
    width: 100%;
  }

  .slider__contents{
    padding: 10px;
    height: auto;
  }

  .bottom__join{
    display: flex;
    flex-wrap: wrap;
  }
}

/* Ends - Media Queries - Academy Page CSS  */