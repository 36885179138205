.container__IntroEvents {
  position: relative;
  height: 100vh;
  z-index: 1;
  background: #bdc3c7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #0071bd,
    #bdbdbd
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #0071bd,
    #bdbdbd
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.row__IntroEvents {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3.125rem;
  width: 1300px;
  height: 100%;
  margin: auto;
}

.left__IntroEvents {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 3;
}

.text__IntroEvents {
  padding-bottom: 20px;
}

.right__IntroEvents {
  flex: 1;
  background-color: rgba(14, 83, 127, 0.5);
  backdrop-filter: blur(2px);
  border-radius: 20px;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.object__IntroEvents {
  width: 90%;
  height: auto;
}

/*
-------------------------------------
        Media Queries
-------------------------------------
*/

/* Starts - Media Queries - IntroEvents Component CSS  */
@media screen and (max-width: 1024px) {
   .container__IntroEvents {
    height: auto;
    padding: 20px;
  }

  .row__IntroEvents {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .button__about {
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
   .container__IntroEvents {
    height: auto;
    padding: 20px;
  }

  .row__IntroEvents {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .button__about {
    margin: auto;
  }
}

@media screen and (max-width: 540px) {
   .container__IntroEvents {
    height: auto;
    padding: 20px;
  }

  .row__IntroEvents {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .button__about {
    margin: auto;
  }
}

@media screen and (max-width: 450px) {
   .container__IntroEvents {
    height: auto;
    padding: 20px;
  }

  .row__IntroEvents {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .button__about {
    margin: auto;
  }
}

@media screen and (max-width: 376px) {
   .container__IntroEvents {
    height: auto;
    padding: 20px;
  }

  .row__IntroEvents {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .button__about {
    margin: auto;
  }
}

@media screen and (max-width: 360px) {
  .container__IntroEvents {
    height: auto;
    padding: 20px;
  }

  .row__IntroEvents {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 50px;
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .button__about {
    margin: auto;
  }
}
/* Ends - Media Queries - IntroEvents Component CSS  */
